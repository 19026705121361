<template>
    <div>
<section id="homesec" class="hero d-flex align-items-center" style="padding-bottom:0px;" v-if="!(language.indexOf('es') === -1)">>
        <div class="container" style="max-width: 1230px;">
      <div class="row" style="height: 100%; margin: 0;">
        <div class="col-lg-6 d-flex flex-column justify-content-center" style="padding-left:40px">
          <div style="text-align:left;"><h2 data-aos="fade-up" style="display: inline;">Conocé</h2> <h1 data-aos="fade-up" style="display: inline;"> Splunk </h1></div>
          <h2 data-aos="fade-up" data-aos-delay="400" style="text-align:left"> </h2>
          <br>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <router-link :to="{ name: 'Splunk', params: { userId: 123 }}" v-scroll-to="'#contact'" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Comenzar</span>
                <i class="bi bi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200" style="height:150hv; height: 100%; padding: 0;">
          <iframe style="width: 600px;height: 441px;" src="https://youtube.com/embed/Kl1C4lle11U?autoplay=1" frameborder="0" title="TopiaVideo"></iframe>
        </div>
      </div>
    </div>
  </section><!-- End Hero -->

    <section id="homesec" class="hero d-flex align-items-center" style="padding-bottom:0px;" v-if="(language.indexOf('es') === -1)">>
        <div class="container" style="max-width: 1230px;">
      <div class="row" style="height: 100%; margin: 0;">
        <div class="col-lg-6 d-flex flex-column justify-content-center" style="padding-left:40px">
           <div style="text-align:left;"><h2 data-aos="fade-up" style="display: inline;">Meet </h2> <h1 data-aos="fade-up" style="display: inline;"> Splunk </h1> <h2 data-aos="fade-up" style="display: inline;"> for your business </h2> </div>
          <h2 data-aos="fade-up" data-aos-delay="400" style="text-align:left"> </h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <router-link :to="{ name: 'Splunk', params: { userId: 123 }}" v-scroll-to="'#contact'" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Start</span>
                <i class="bi bi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200" style="height:150hv; height: 100%; padding: 0;">
          <iframe style="width: 600px;height: 441px;" src="https://youtube.com/embed/Kl1C4lle11U?autoplay=1" frameborder="0" title="TopiaVideo"></iframe>
        </div>
      </div>
    </div>
  </section><!-- End Hero -->
      <section class="divcenter" v-if="language == 'nomostrar'" data-aos="fade-up">
        <div><a  v-if="mobileView" rel="noopener noreferrer" href="https://forms.gle/nTXfNqEPDnimo1tV6" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
            <span>Ver una DEMO</span>
        </a>
        <DemoVicarius  v-if="!mobileView" :language = "dominioactual"/>
        </div>
    </section>
        <section id="soluciones" class="values" v-if="language == 'nomostrar'">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>¿Porqué Topia?</p>
          </header>
          <div class="row">
            <div class="col-lg-6">
              <router-link :to="{ name: 'Vicarius', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/sec/vicarius.webp" class="img-fluid" alt="">
                <h3>MITIGA las vulnerabilidades </h3>
                <h3>MINIMIZA el riesgo </h3>
                <p> Mantenga actualizado los sistemas y aplicaciones de su organización</p>
                <p> Manténgase protegido con parches virtuales hasta que haya un parche de proveedor disponible</p>
                <p> Remediación preparada para la nube</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-6 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Vicarius', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/clients/03.webp" class="img-fluid" alt="">
                <h3>Maximiza la eficiencia en GESTION de vulnerabilidades</h3>
                <p>Consolidá Priorizá y Remediá en una sola plataforma</p>
                <p>Simplificá la detección y remediación de brechas de seguridad </p>
                <p>Automatizá la rotina de updates para todo el ambiente </p>
                <p>Incrementá la colaboración entre equipos de IT y Seguridad</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    <section class="divcenter" v-if="language == 'nomostrar'" data-aos="fade-up">
        <div><a  v-if="mobileView" rel="noopener noreferrer" href="https://forms.gle/nTXfNqEPDnimo1tV6" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
            <span>Ver una DEMO</span>
        </a>
        <DemoVicarius  v-if="!mobileView" :language = "dominioactual"/>
        </div>
    </section>
    <section class="divcenter" v-if="language == 'nomostrar'" data-aos="fade-up">
        <div><a  v-if="mobileView" rel="noopener noreferrer" href="https://forms.gle/nTXfNqEPDnimo1tV6" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
            <span>Register for a DEMO</span>
        </a>
        <DemoVicarius  v-if="!mobileView" :language = "dominioactual"/>
        </div>
    </section>
      <section id="soluciones" class="values" v-if="language == 'nomostrar'">
          <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Solutions</h2>
            <p>Why Topia?</p>
          </header>
          <div class="row">
            <div class="col-lg-6">
              <router-link :to="{ name: 'Vicarius', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/sec/vicarius.webp" class="img-fluid" alt="">
                <h3>MITIGA las vulnerabilidades </h3>
                <h3>MINIMIZA el riesgo </h3>
                <p> Mantenga actualizado los sistemas y aplicaciones de su organización</p>
                <p> Manténgase protegido con parches virtuales hasta que haya un parche de proveedor disponible</p>
                <p> Remediación preparada para la nube</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-6 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Vicarius', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/clients/03.webp" class="img-fluid" alt="">
                <h3>Maximiza la eficiencia en GESTION de vulnerabilidades</h3>
                <p>Consolidá Priorizá y Remediá en una sola plataforma</p>
                <p>Simplificá la detección y remediación de brechas de seguridad </p>
                <p>Automatizá la rotina de updates para todo el ambiente </p>
                <p>Incrementá la colaboración entre equipos de IT y Seguridad</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
        <section class="divcenter" v-if="language == 'nomostrar'" data-aos="fade-up">
        <div><a  v-if="mobileView" rel="noopener noreferrer" href="https://forms.gle/nTXfNqEPDnimo1tV6" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
            <span>Register for a DEMO</span>
        </a>
        <DemoVicarius  v-if="!mobileView" :language = "dominioactual"/>
        </div>
    </section>
    </div>
</template>

<script>
import DemoVicarius from '../../components/DemoVicarius.vue'

export default {
  props: {
    language: String
  },
  components: {
    DemoVicarius
  },
  data: () => {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  }
}
</script>

<style>

.divcenter{
    position: relative;
    left: 40%;
    margin-top: -50px;
    margin-left: -50px;
    width: 330px;
    height: 100px;
}

</style>
